<template>
  <v-bottom-sheet v-model="dialog">
    <v-sheet class="text-center" :height="containerHeight">
      <v-btn class="mt-6" text color="red" @click="closeDialog"
        >close</v-btn
      >
      <div class="py-3">
        <qrcode :value="value" :options="QRCodeOptions"></qrcode>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import qrcode from "@chenfengyuan/vue-qrcode";
import dialogMixin from "@/mixins/dialogMixin"
export default {
  data() {
    return {
      // viewQRCode: false,
      containerHeight: "400px",
      QRCodeOptions: {},
      // value: "0",
    };
  },
  props: {
    value: {
      type: String,
      default: "0",
    },
  },
  created() {
    window.addEventListener("resize", this.resizeModel);
    this.resizeModel();
    this.showDialog();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeModel);
  },
  methods: {
    show(data) {
      this.value = data;
      this.resizeModel();
      this.showDialog()
    },
    resizeModel() {
      if (window.innerWidth > 500) {
        this.QRCodeOptions = { width: 500 };
        this.containerHeight = "600px";
      } else {
        this.QRCodeOptions = { width: window.innerWidth };
        this.containerHeight = window.innerWidth + 100;
      }
    },
  },
  // watch: {
  //   viewQRCode: function() {
  //     if (this.viewQRCode == false) {
  //       this.$emit('close');
  //     }
  //   },
  // },
  components: {
    qrcode,
  },
  watch: {
    dialogQR(newValue, oldValue) {
      console.log('dialogQR', newValue + ' ' + oldValue)
    }
  },
  mixins: [dialogMixin]
};
</script>
